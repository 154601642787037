import PropTypes from "prop-types";
import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

function Dropdown({ data, label, itemKey }) {
  const [dropdown, setDropdown] = useState(null);

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => {
    setDropdown(null);
  };

  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  return (
    <MKBox component="section" py={1} px={1}>
      <Container maxWidth="xs">
        <Grid container spacing={1}>
          <Grid item xs={12} textAlign="center">
            <MKButton variant="gradient" color="info" onClick={openDropdown}>
              {label}
              <Icon sx={dropdownIconStyles}>expand_more</Icon>
            </MKButton>
            <Menu
              anchorEl={dropdown}
              open={Boolean(dropdown)}
              onClose={closeDropdown}
              PaperProps={{
                style: {
                  overflowX: "hidden",
                  overflowY: "scroll",
                },
              }}
              MenuListProps={{
                style: {
                  padding: 0,
                },
              }}
            >
              {data?.map((item) => (
                // oficina-detalles/{estado}/{ciudad}
                <Link key={item.id} to={"/oficina-detalles/" + label + "/" + item.Nombre}>
                  <MenuItem
                    onClick={() => closeDropdown()}
                    sx={{
                      padding: "8px 16px", // Ajustar el padding para reducir espacio en blanco
                      minHeight: "auto",
                      lineHeight: "1.5",
                    }}
                  >
                    {item[itemKey]}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Dropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  itemKey: PropTypes.string,
  label: PropTypes.string,
};

export default Dropdown;
