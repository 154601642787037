/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  return (
    <MKBox
      width="100%"
      component="footer"
      sx={{ backgroundColor: "#ebecedff !important", justifyContent: "space-around" }}
      mx={1}
      pt={{ xs: 8, md: 12 }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={5} sx={{ mb: 3 }} ml={{ xs: 0, md: 6 }}>
            <MKBox maxWidth="30ch">
              {/* border="1px solid red !important" */}
              <Link to={brand.route}>
                <MKBox
                  component="img"
                  src={brand.image}
                  alt={brand.name}
                  maxWidth="8rem"
                  mt={-1.5}
                  mb={2}
                />
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items, itemsJson, itemKey }) => (
            <Grid
              key={title}
              item
              xs={6}
              md={2}
              sx={{ mb: 3 }}
              /* , border: "1px solid blue !important"  */
            >
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {(itemsJson && itemsJson.length > 0 ? itemsJson : items).map((item) => {
                  const itemName = itemsJson ? item[itemKey] : item.name;
                  return (
                    <MKBox key={itemName} component="li" p={0} m={0} lineHeight={1.25}>
                      {item.href ? (
                        <MKTypography
                          component="a"
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {itemName}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={item.route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {itemName}
                        </MKTypography>
                      )}
                    </MKBox>
                  );
                })}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            {socials.map(({ icon, link }, key) => (
              <MKTypography
                key={link}
                component="a"
                href={link}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
                mr={key === socials.length - 1 ? 0 : 2.5}
              >
                {icon}
              </MKTypography>
            ))}
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center", mt: 0, mb: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
