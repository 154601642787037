import { useEffect } from "react";
import { Route, useLocation, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { AnimatePresence, motion } from "framer-motion";
import AnimationLayout from "promessa/components/AnimationLayout";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import routes from "promessa/routes";
import Fab from "@mui/material/Fab";
import ChatBubbleRounded from "@mui/icons-material/ChatBubbleRounded";
import PromessaHome from "promessa/pages/Home";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return null;
}

const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });

export default function App() {
  /*   const [isChatOpen, setIsChatOpen] = useState(false);*/

  function openTally() {
    // Load all embeds on the page
    // eslint-disable-next-line no-undef
    Tally.openPopup("mJLMWd");
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <Routes>
          <Route element={<AnimationLayout />}>
            {getRoutes(routes)}
            <Route
              path="*"
              element={
                <motion.div>
                  <PromessaHome />
                </motion.div>
              }
            />
          </Route>
        </Routes>
      </AnimatePresence>
      <Fab
        /* onClick={() => setIsChatOpen(true)} */
        onClick={openTally}
        size="large"
        color="warning"
        aria-label="form"
        sx={{ position: "absolute", right: 32, bottom: 32 }}
      >
        <ChatBubbleRounded color="white" fontSize="large" />
      </Fab>
      {/* {isChatOpen && (
        <iframe
          data-tally-src="https://tally.so/embed/mJLMWd?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
          loading="lazy"
          width="100%"
          height="276"
          title="Contacto - Promessa"
        ></iframe>
      )} */}
    </ThemeProvider>
  );
}
