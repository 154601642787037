/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

// Images
import bgImage from "promessa/assets/images/banner_login.jpg";
//import bgImageMobile from "promessa/assets/images/mobile/banner_home_mobile.jpg";
//import asegurateText from "promessa/assets/images/boton asegurate aqui.png";
//import { Link } from "react-router-dom";
import iconWhite from "promessa/assets/images/icon_white.png";
import MKInput from "components/MKInput";
import { Checkbox } from "@mui/material";

function LoginForm() {
  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundColor: "#225399",
          backgroundImage: { xs: ``, md: `url(${bgImage})` },
          backgroundSize: { xs: "cover", md: "cover" },
          backgroundPosition: { xs: "center center", md: "top" },
          backgroundRepeat: "no-repeat",
          display: "flex",
          placeItems: { xs: "center", md: "center" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MKBox
          style={{ backgroundColor: "white", width: "400px", height: "400px", borderRadius: 10 }}
          sx={{
            px: 3,
            gap: 3,
          }}
        >
          <MKBox
            sx={{
              mt: -2,
              placeItems: "center",
              mx: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              p: 2,
            }}
            style={{
              background:
                "linear-gradient(90deg, rgba(255,198,41,1) 0%, rgba(255,198,41,1) 42%, rgba(255,158,13,1) 100%)",
              borderRadius: 10,
            }}
          >
            <MKBox component="img" src={iconWhite} sx={{ width: 50, height: 60 }} />
            <MKTypography variant="subtitle" color="white">
              Inicio de Sesión
            </MKTypography>
          </MKBox>
          <MKInput sx={{ width: "100%", mt: 1 }} label="Email"></MKInput>
          <MKInput sx={{ width: "100%", mt: 1 }} label="Contraseña" type="password"></MKInput>
          <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Checkbox />
            <MKTypography variant="subtitle2">Recuérdame</MKTypography>
          </div>
          <MKButton sx={{ width: "100%", maxWidth: "100%", backgroundColor: "#225399", mt: 1 }}>
            <MKTypography sx={{ textAlign: "center", color: "white" }} variant="h5" color="white">
              INGRESAR
            </MKTypography>
          </MKButton>
        </MKBox>
      </MKBox>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

export default LoginForm;
