import DefaultFooter from "promessa/components/DefaultFooter";
import DefaultNavbar from "promessa/components/DefaultNavbar";
import footerRoutes from "promessa/footer.routes";
import routes from "promessa/routes";

import "./estilo.css";
import MKBox from "components/MKBox";
import { Container } from "@mui/material";
import { Grid } from "@mui/material";
import { PropTypes } from "prop-types";

export default function Plantilla({ children }) {
  return (
    <div>
      <DefaultNavbar routes={routes} sticky transparent light />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundColor: "#225399",
          display: "flex",
          placeItems: { xs: "flex-start", md: "center" },
          paddingTop: { xs: "120px", md: 0 },
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "flex-start", md: "center" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              paddingLeft: { xs: "16px", md: 0 },
            }}
          >
            <Grid item textAlign={{ xs: "center", md: "left" }}>
              <div>{children}</div>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

Plantilla.propTypes = {
  children: PropTypes.node,
};
